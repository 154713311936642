import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import moment from 'moment';
import Layout from '../components/layout';
import Attribution from '../components/attribution';
import ContentBody from '../components/ContentBody';
import SEO from '../components/seo';
import { theme } from '../constants/theme';
import { ProjectsList } from '../components/ProjectsList';
import { linkResolver } from '../utils/linkResolver';
import { Arrow } from '../images/arrow';

import 'moment/locale/es';
import 'moment/locale/pt';

export const query = graphql`
  query Project($uid: String, $lang: String) {
    prismic {
      allProjects(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
              type
            }
            title
            start_date
            listing_image
            listing_video {
              ... on PRISMIC__FileLink {
                _linkType
                url
                size
                name
              }
            }
            end_date
            description
            project_type
            project_location
              body1 {
              ... on PRISMIC_ProjectBody1Single_image {
                type
                label
                primary {
                  image
                  video {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_ProjectBody1Double_image {
                type
                label
                primary {
                  image_left
                  image_right
                  video_right {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  video_left {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_ProjectBody1Full_width_image {
                type
                label
                primary {
                  image
                  video {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_ProjectBody1Image___text {
                type
                label
                primary {
                  content
                  image
                  video {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
            projects {
              project {
                ... on PRISMIC_Project {
                  title
                  listing_image
                  listing_video {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  end_date
                  start_date
                  project_type
                  _meta {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ProjectDetail = ({ data, location }) => {
  const project = data.prismic.allProjects.edges.slice(0, 1).pop();

  if (!project || !project.node) {
    return null;
  }

  const startDate = project.node.start_date;
  const endDate = project.node.end_date;

  const momentFormatString = 'D MMMM YYYY';
  let moreHeader = 'MÁS PROYECTOS';
  let viewAll = 'Ver todos los proyectos';
  const { lang } = project.node._meta;

  if (lang === 'es-mx') {
    moreHeader = 'MÁS PROYECTOS';
    viewAll = 'Ver todos los proyectos';
    moment.locale('es');
  } else if (lang === 'pr-br') {
    moreHeader = 'MAIS PROJETOS';
    viewAll = 'Veja todos os projetos';
    moment.locale('pt');
  } else {
    moreHeader = 'MORE PROJECTS';
    viewAll = 'See all projects';
    moment.locale('en');
  }

  return (
    <Layout color="white" lang={project.node._meta.lang} location={location}>
      <SEO
        title={project.node.title}
        description={project.node.description && RichText.asText(project.node.description)}
      />
      <ProjectContainer>
        <ProjectHeaderSizer>
          { project.node.listing_video ? (
            <ProjectHeader backgroundImageUrl={
              project.node.listing_image && project.node.listing_image.url
}
            >
              <ProjectTitleOverlay>
                <ProjectTitle>{project.node.title}</ProjectTitle>
              </ProjectTitleOverlay>
              <video id="background-video" autoPlay loop muted playsInline>
                <source src={project.node.listing_video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </ProjectHeader>
          ) : (
            <ProjectHeader backgroundImageUrl={project.node.listing_image.url}>
              <ProjectTitleOverlay>
                <ProjectTitle>{project.node.title}</ProjectTitle>
              </ProjectTitleOverlay>
            </ProjectHeader>
          )}

        </ProjectHeaderSizer>

        <ProjectContent>
          <AttributionBox>
            <ProjectHeaderAttribution image={project.node.listing_image} />
          </AttributionBox>

          <ProjectIntro>
            <ProjectIntroInfo>
              <p>{project.node.project_type}</p>
              <p>{project.node.project_location}</p>
              <p>{`${moment(startDate).format(momentFormatString)}${project.node.end_date ? (` - ${moment(endDate).format(momentFormatString)}`) : ''}`}</p>
            </ProjectIntroInfo>
            <ProjectInfoDescription>
              {RichText.render(project.node.description)}
            </ProjectInfoDescription>
          </ProjectIntro>
          { project.node.body1 && (
            <ContentBody body={project.node.body1} />
          ) }
        </ProjectContent>
      </ProjectContainer>
      { project.node && project.node.projects && project.node.projects.length !== 0 && (
        <ProjectRecirculation>
          <SectionHeader>
            <h3>{moreHeader}</h3>
            <Link to={linkResolver({ type: 'project_listing_page', lang })}>
              <ViewAllText>{viewAll}</ViewAllText>
              <Arrow />
            </Link>
          </SectionHeader>
          <ProjectsList projects={project.node.projects.map((p) => p.project)} />
          <MobileLearnMore>
            <Link to={linkResolver({ type: 'project_listing_page', lang })}>
              <ViewAllText>{viewAll}</ViewAllText>
              <Arrow />
            </Link>
          </MobileLearnMore>
        </ProjectRecirculation>
      )}
    </Layout>
  );
};

const ProjectRecirculation = styled.div`
  margin-top: 86px;
  @media ${theme.breakpoints.size.m} {
      margin-top: 36px;
  }
  @media ${theme.breakpoints.size.m} {
      margin-top: 11px;
  }
  ul {
    margin-bottom: 48px;

    @media ${theme.breakpoints.size.xl} {
      margin-bottom: 71px;
    }
    @media ${theme.breakpoints.size.m} {
      margin-top: 36px;
      margin-bottom: 22px;
    }

    @media ${theme.breakpoints.size.s} {
      margin-bottom: 0px;
    }

    li:last-child{
      margin-bottom: 58px;
    }
  }
`;

const MobileLearnMore = styled.div`
  align-items: center;
  justify-content: center;
  display: none;
  margin-bottom: 61px;
  @media ${theme.breakpoints.size.s} {
    display: flex;
  }

  a {
    display: flex;
    align-items: center;
    color: ${theme.colors.red};
    font-size: 18px;
    line-height: 38px;
    text-decoration: none;

    &:visited {
      color: ${theme.colors.red};
    }
  }
`;

const AttributionBox = styled.div`
  margin-top: 20px;
`;

const ProjectHeaderAttribution = styled(Attribution)`
  margin-top: 20px !important;
`;

const ViewAllText = styled.p`
  margin: 0 10px;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.red};
  margin: 0 80px 30px 80px;

  @media ${theme.breakpoints.size.m} {
    margin: 0px 40px 30px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    justify-content: center;
    margin: 0px 20px 30px 20px;
  }

  h3 {
    margin: 0 0;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 36px;
    font-weight: 400;
    flex: 1;
    @media ${theme.breakpoints.size.s} {
      font-size: 25px;
    }

    @media ${theme.breakpoints.size.s} {
      text-align: center;
    }
  }
  a {
    display: flex;
    align-items: center;
    color: ${theme.colors.red};
    font-size: 18px;
    line-height: 38px;
    text-decoration: none;
    @media ${theme.breakpoints.size.s} {
      display: none;
    }

    &:visited {
      color: ${theme.colors.red};
    }
  }
`;

const ProjectContent = styled.div`
  margin: 0 80px;

  @media ${theme.breakpoints.size.l} {
    margin: 0px 40px;
  }
  @media ${theme.breakpoints.size.s} {
    margin: 0px 20px;
  }
`;

const ProjectIntro = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin: 73px 0;
  padding-right: 40px;
  @media ${theme.breakpoints.size.xl} {
    padding-right: 0px;
  }

  @media ${theme.breakpoints.size.m} {
    margin: 50px 0px;
  }

  @media ${theme.breakpoints.size.s} {
    margin: 43px 0 44px;
    flex-direction: column;
  }

  p {
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
`;

const ProjectIntroInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media ${theme.breakpoints.size.s} {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const ProjectInfoDescription = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${theme.breakpoints.size.s} {
    text-align: center;
  }
`;

const ProjectTitle = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 90px;
  text-align: center;
  text-transform: uppercase;
  margin: auto;
  width: 74%;
  @media ${theme.breakpoints.size.xl} {
    font-size: 60px;
  }
  @media ${theme.breakpoints.size.m} {
    font-size: 50px;
    width: auto;
  }
  @media ${theme.breakpoints.size.s} {
    width: 100%;
    font-size: 30px;
    margin: 0 20px;
  }
`;

const ProjectTitleOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(219,218,218);
  background: linear-gradient(180deg, rgba(219,218,218,1) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
`;

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -110px;

  z-index: 1;
`;

const ProjectHeader = styled.div`
  align-items: center;
  background: url(${(props) => props.backgroundImageUrl});
  background-position: center;
  background-size: cover;
  display: flex;
  height: 800px;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const ProjectHeaderSizer = styled.div`
  position: relative;
  height: 101.5vh;
  overflow: hidden;

  @media ${theme.breakpoints.size.m} {
    padding-top: 55%;
    height: 100%;
  }

  @media ${theme.breakpoints.size.s} {
    height: 103vh;
    padding: 0;
  }
`;

export default ProjectDetail;
